const refreshSignaturesOrInitials = (json_data, form_ids, ids) => {
    // console.log("@@@@@@@@@@@ refreshSignaturesOrInitials Web:", {
    //     form_ids,
    //     ids
    // })

    if (json_data.forms) {
        for (let j = 0; j < form_ids.length; j++) {
            const form = form_ids[j]
            delete json_data.forms[form]
        }
    }
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i]
        delete json_data[id]
        delete json_data[`${id}_date`]
    }
}
const REFILL_FORMS_IDS = {
    withNoOnchange: {
        forms: [
            "him_technical_standards",
            "him_enrollment_agreement",
            "transferability_of_credits",
            "covid_19",
            "cpa_eligibility",
            "technical_standards",
            "felony_acknowledgement",
            "online_education_disclosure",
            "code_of_computing",
            "identity_authentication"
        ],
        ids: [
            "student_signature_himTechnicalStandards",
            "student_signature_HIMEnrollmentAgreement",
            "student_signature_toc",
            "student_signature_covid19",
            "student_signature_cpa_eligibility",
            "student_signature_technicalStandards_1",
            "student_initials_technicalStandards_1",
            "student_signature_fa",
            "student_signature_oed",
            "student_signature_coc",
            "student_signature_ia"
        ]
    },
    application: {
        forms: ["application"],
        ids: ["student_signature_1"]
    },
    identity_authentication: {
        forms: ["identity_authentication"],
        ids: ["student_signature_ia"]
    },
    education_disclosure: {
        forms: ["education_disclosure"],
        ids: ["student_signature_ed"]
    },
    immunization_verification: {
        forms: ["immunization_verification"],
        ids: ["student_signature_iv"]
    },
    transcript_request: {
        forms: ["transcript_request"],
        ids: ["student_signature_transcriptRequest"]
    },
    recruitment: {
        forms: ["recruitment"],
        ids: ["student_signature_Recruitment"]
    }
}
const ALL_FORMS_IN_TABS = [
    "application_career_information",
    "application_personal_information",
    "application_contact_details",
    "application_emergency_contact",
    "application_additional_information",
    "application_employment_details",
    "application_high_school_details",
    "application_previous_college",

    "application",
    "education_disclosure",
    "him_technical_standards",
    "him_enrollment_agreement",
    "immunization_verification",
    "transferability_of_credits",
    "transcript_request",
    "recruitment",
    "covid_19",
    "cpa_eligibility",
    "technical_standards",
    "felony_acknowledgement",
    "online_education_disclosure",
    "code_of_computing",
    "identity_authentication",

    "appointment_details",
    "submission"
]

const SAVE_CHANGES_MESSAGE = "Page was not saved. Please Press Save & Continue to keep the changes"

export {refreshSignaturesOrInitials, REFILL_FORMS_IDS, SAVE_CHANGES_MESSAGE, ALL_FORMS_IN_TABS}
